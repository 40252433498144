/**
 * Types Price List
 * @type {string}
 */
export const SEARCH_PRICE_LIST = 'SEARCH_PRICE_LIST';
export const DETAILS_PRICE_LIST = 'DETAILS_PRICE_LIST';
export const ADD_PRICE_LIST = 'ADD_PRICE_LIST';
export const DELETE_PRICE_LIST = 'DELETE_PRICE_LIST';
export const UPDATE_PRICE_LIST = 'UPDATE_PRICE_LIST';
export const SEARCH_PRICE_LIST_NEXT = 'SEARCH_PRICE_LIST_NEXT';
export const SEARCH_PRICE_LIST_PREVIOUS = 'SEARCH_PRICE_LIST_PREVIOUS';
export const LIST_MIN_PRICE_LIST = 'LIST_MIN_PRICE_LIST';

/**
 * Types Deposit
 * @type {string}
 */
export const DETAILS_DEPOSIT = 'DETAILS_DEPOSIT';
export const UPDATE_DEPOSIT = 'UPDATE_DEPOSIT';

/**
 * Types Credit
 * @type {string}
 */
export const ADD_CREDIT = 'ADD_CREDIT';
export const UPDATE_CREDIT = 'UPDATE_CREDIT';
export const ADD_FILE_IMPORTED_CREDIT = 'ADD_FILE_IMPORTED_CREDIT';
export const LIST_MIN_CREDIT = 'LIST_MIN_CREDIT';
export const SEARCH_FILE_IMPORTED_CREDIT = 'SEARCH_FILE_IMPORTED_CREDIT';
export const DELETE_FILE_IMPORTED_CREDIT = 'DELETE_FILE_IMPORTED_CREDIT';

/**
 * Types Debits
 * @type {string}
 */
export const ADD_DEBIT = 'ADD_DEBIT';
export const UPDATE_DEBIT = 'UPDATE_DEBIT';

/**
 * Types credit and debit
 * @type {string}
 */
export const LIST_MIN_TYPE_CREDIT = 'LIST_MIN_TYPE_CREDIT';
export const LIST_MIN_TYPE_DEBIT = 'LIST_MIN_TYPE_DEBIT';

/**
 * Types vouchers
 * @type {string}
 */
export const ADD_VOUCHER = 'ADD_VOUCHER';

/**
 * Types configurations
 * @type {string}
 */
export const SEARCH_CONFIGURATION_MAKE_DEBIT = 'SEARCH_CONFIGURATION_MAKE_DEBIT';
export const SEARCH_CONFIGURATION_CUT_SERVICE = 'SEARCH_CONFIGURATION_CUT_SERVICE';
export const EXECUTED_MAKE_DEBIT = 'EXECUTED_MAKE_DEBIT';
export const EXECUTED_CUT_SERVICE = 'EXECUTED_CUT_SERVICE';


/**
 * Types conekta pay
 * @type {string}
 */
export const SEARCH_CONEKTA_PAY = 'SEARCH_CONEKTA_PAY';
