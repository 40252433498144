import {
  ADD_BUSINESS_CLIENT_GROUPS,
  ADD_CLIENT,
  DELETE_BUSINESS_CLIENT_GROUPS,
  DELETE_CLIENT,
  DETAILS_BUSINESS_CLIENT_GROUPS,
  DETAILS_CLIENT,
  DISABLED_CLIENTS,
  ENABLED_CLIENTS,
  LIST_MIN_CLIENTS,
  SEARCH_BUSINESS_CLIENT_GROUPS,
  SEARCH_CLIENT,
  SEARCH_CLIENT_NEXT,
  SEARCH_CLIENT_PREVIOUS,
  SEARCH_LOGS_CLIENT,
  SYNC_CLIENT_RADIUS,
  UPDATE_BUSINESS_CLIENT_GROUPS,
  UPDATE_CLIENT,
  UPDATING_MONITORING_CLIENTS
} from "../remote-service/types-client";

const initialState = {
  clients: [],
  clients_min: [],
  client: null,
  next: null,
  previous: null,
  disabledClients: [],
  enabledClients: [],
  lastClientStatusChanged: null,
  businessClientsGroups: [],
  businessClientsGroup: null,
  client_logs: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_MIN_CLIENTS:
      return {
        ...state,
        clients_min: action.payload
      };
    case SEARCH_CLIENT:
      return {
        ...state,
        clients: action.payload
      };
    case SEARCH_CLIENT_NEXT:
      return {
        ...state,
        next: action.payload
      };
    case SEARCH_CLIENT_PREVIOUS:
      return {
        ...state,
        previous: action.payload
      };
    case DETAILS_CLIENT:
      return {
        ...state,
        client: action.payload
      };
    case DELETE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter(client => client.pk !== action.payload)
      };
    case ADD_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.payload]
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        clients: state.clients.map(client =>
          client.pk === action.payload ? (client = action.payload) : client
        )
      };
    case UPDATING_MONITORING_CLIENTS:
      return {
        ...state,
        clients: state.clients.map(client =>
          client.pk === action.payload ? (client = action.payload) : client,
        ),
        lastClientStatusChanged: action.payload.monitoring_status
      };
    case SYNC_CLIENT_RADIUS:
      return {
        ...state,
        clients: [...state.clients, action.payload]
      };
    case DISABLED_CLIENTS:
      return {
        ...state,
        disabledClients: action.payload
      };
    case ENABLED_CLIENTS:
      return {
        ...state,
        enabledClients: action.payload
      };
    case SEARCH_BUSINESS_CLIENT_GROUPS:
      return {
        ...state,
        businessClientsGroups: action.payload
      };
    case DETAILS_BUSINESS_CLIENT_GROUPS:
      return {
        ...state,
        businessClientsGroup: action.payload
      };
    case DELETE_BUSINESS_CLIENT_GROUPS:
      return {
        ...state,
        businessClientsGroups: state.businessClientsGroups.filter(businessClientsGroup => businessClientsGroup.pk !== action.payload)
      };
    case ADD_BUSINESS_CLIENT_GROUPS:
      return {
        ...state,
        businessClientsGroups: [...state.businessClientsGroups, action.payload]
      };
    case UPDATE_BUSINESS_CLIENT_GROUPS:
      return {
        ...state,
        businessClientsGroups: state.businessClientsGroups.map(businessClientsGroup =>
          businessClientsGroup.pk === action.payload ? (businessClientsGroup = action.payload) : businessClientsGroup
        )
      };
    case SEARCH_LOGS_CLIENT:
      return {
        ...state,
        client_logs: action.payload
      };
    default:
      return state;
  }
}
