import {LIST_MIN_TELEGRAM_BOT, TELEGRAM_TEST_CONFIGURATION} from "../remote-service/types-notifications";

const initialState = {
  telegramsBotsMin: [],
  telegramTestConfigurationResponse: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_MIN_TELEGRAM_BOT:
      return {
        ...state,
        telegramsBotsMin: action.payload
      };
    case TELEGRAM_TEST_CONFIGURATION:
      return {
        ...state,
        telegramTestConfigurationResponse: action.payload
      };
    default:
      return state;
  }
}
