import {SEARCH_CONEKTA_PAY} from "../remote-service/types-collection";

const initialState = {
  pays: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_CONEKTA_PAY:
      return {
        ...state,
        pays: action.payload
      };
    default:
      return state;
  }
}
