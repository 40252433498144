import {SEARCH_CLIENT_TRASH,} from "../remote-service/types-client";

const initialState = {
  clientsTrash: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_CLIENT_TRASH:
      return {
        ...state,
        clientsTrash: action.payload
      };
    default:
      return state;
  }
}
