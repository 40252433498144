import {combineReducers} from 'redux';
import userReducers from './apps/security/reducers/user-reducers';
import roleReducers from './apps/security/reducers/role-reducers';
import permissionsReducers from './apps/security/reducers/permission-reducers';
import authReducers from './apps/security/reducers/auth-reducers';
import wayPayReducers from './apps/client/reducers/way-pay-reducers';
import serviceStatusReducers from './apps/client/reducers/service-status-reducers';
import DTROReducers from './apps/client/reducers/digital-tax-receipt-online-reducers';
import clientReducers from './apps/client/reducers/client-reducers';
import radiusReducers from './apps/radius/reducers/radius-reducers';
import referencePersonReducers from './apps/client/reducers/reference-person-reducers';
import contactReducers from './apps/client/reducers/contact-reducers';
import priceListreducers from './apps/collection/reducers/price-list-reducers';
import depositReducers from './apps/collection/reducers/deposit-reducers';
import creditReducers from './apps/collection/reducers/credit-reducers';
import debitReducers from './apps/collection/reducers/debit-reducers';
import typeDebitCreditReducers from './apps/collection/reducers/type-debit-credit-reducers';
import zoneReducers from './apps/client/reducers/zone-reducers';
import informationReducers from './apps/report/reducers/information-reducers';
import zoneCategoryReducers from './apps/client/reducers/zone-category-reducers';
import voucherReducers from './apps/collection/reducers/voucher-reducers';
import oxxoReducers from './apps/client/reducers/oxxo-reducers';
import ticketReducers from './apps/support/reducers/ticket-reducers';
import nasTicketReducers from './apps/support/reducers/nas-ticket-reducers';
import technicalReducers from './apps/support/reducers/technical-reducers';
import ticketTypeReducers from './apps/support/reducers/ticket-type-reducers';
import ticketsProblemsReducers from './apps/support/reducers/ticket-problems-reducers';
import ticketsSolutionsReducers from './apps/support/reducers/ticket-solutions-reducers';
import nasTicketsProblemsReducers from './apps/support/reducers/nas-ticket-problems-reducers';
import nasTicketsSolutionsReducers from './apps/support/reducers/nas-ticket-solutions-reducers';
import telegramsBotsReducers from './apps/notifications/reducers/telegrams_bots-reducers';
import dashboardReducers from './apps/dashboard/reducers/dashboard-type-reducers';
import equipmentReducers from './apps/infrastructure/reducers/equipment-reducers';
import nasInfoReducers from './apps/support/reducers/nas-info-reducers';
import scheduledReducers from './apps/collection/reducers/scheduled-reducers';
import clientTrashReducers from './apps/client/reducers/client-trash-reducers';
import taskReducers from './apps/task/reducers/task-reducers';
import taskTypeReducers from './apps/task/reducers/task-type-reducers';
import providerReducers from './apps/shopping/reducers/provider-reducers';
import productReducers from './apps/shopping/reducers/product-reducers';
import buyReducers from './apps/shopping/reducers/buy-reducers';
import kitReducers from './apps/shopping/reducers/kit-reducers';
import conektaPayReducers from './apps/collection/reducers/conekta-pay-reducers';
import mobileReducers from './apps/mobile/reducers/mobile-reducers';


import dataReducers from './apps/common/reducers/reducers';

export default combineReducers({
  //Common
  data: dataReducers,


  //Security
  users: userReducers,
  roles: roleReducers,
  permissionss: permissionsReducers,
  auth: authReducers,

  //Client
  waysPay: wayPayReducers,
  serviceStatus: serviceStatusReducers,
  DTROs: DTROReducers,
  clients: clientReducers,
  referencePerson: referencePersonReducers,
  contact: contactReducers,
  zone: zoneReducers,
  zoneCategory: zoneCategoryReducers,
  oxxo: oxxoReducers,
  clientTrash: clientTrashReducers,

  //Radius
  radius: radiusReducers,

  //Collections
  priceList: priceListreducers,
  deposit: depositReducers,
  credits: creditReducers,
  debits: debitReducers,
  typeDebitCredit: typeDebitCreditReducers,
  information: informationReducers,
  vouchers: voucherReducers,
  scheduled: scheduledReducers,

  //Support
  tickets: ticketReducers,
  nasTickets: nasTicketReducers,
  technicals: technicalReducers,
  ticketTypes: ticketTypeReducers,
  ticketsProblems: ticketsProblemsReducers,
  ticketsSolutions: ticketsSolutionsReducers,
  nasInfo: nasInfoReducers,
  conektaPay: conektaPayReducers,
  nasTicketsProblems: nasTicketsProblemsReducers,
  nasTicketsSolutions: nasTicketsSolutionsReducers,

  //Notifications
  telegramsBots: telegramsBotsReducers,

  //DashBoard
  dashboard: dashboardReducers,

  //Infrastructure
  equipment: equipmentReducers,

  //Task
  task: taskReducers,
  taskType: taskTypeReducers,

  //Shopping
  provider: providerReducers,
  product: productReducers,
  buy: buyReducers,
  kit: kitReducers,

  //Mobile
  mobile: mobileReducers
});
