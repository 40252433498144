export const SEARCH_DATA = 'SEARCH_DATA';
export const SEARCH_DATA_NEXT = 'SEARCH_DATA_NEXT';
export const SEARCH_DATA_PREVIOUS = 'SEARCH_DATA_PREVIOUS';
export const SEARCH_DATA_TOTAL_ROWS = 'SEARCH_DATA_TOTAL_ROWS';
export const DELETE_DATA = 'DELETE_DATA';

export const SEARCH_BY = 'SEARCH_BY';
export const SEARCH_BY_TYPE_CLIENT = 'SEARCH_BY_TYPE_CLIENT';

export const UPDATE_LOADING = 'UPDATE_LOADING';

export const SEARCH_BY_NAS_IP = 'SEARCH_BY_NAS_IP';
export const ADD_NOTE_FROM_MODAL = 'ADD_NOTE_FROM_MODAL';

