import {
  ADD_TASK_TYPE,
  DELETE_TASK_TYPE,
  DETAILS_TASK_TYPE,
  LIST_MIN_TASK_TYPE,
  SEARCH_TASK_TYPE,
  UPDATE_TASK_TYPE
} from "../remote-service/types-task";

const initialState = {
  taskTypeMin: [],
  taskTypes: [],
  taskType: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_MIN_TASK_TYPE:
      return {
        ...state,
        taskTypeMin: action.payload
      };
    case SEARCH_TASK_TYPE:
      return {
        ...state,
        taskTypes: action.payload
      };
    case DETAILS_TASK_TYPE:
      return {
        ...state,
        taskType: action.payload
      };
    case DELETE_TASK_TYPE:
      return {
        ...state,
        taskTypes: state.taskTypes.filter(tasType => tasType.pk !== action.payload)
      };
    case ADD_TASK_TYPE:
      return {
        ...state,
        taskTypes: [...state.taskTypes, action.payload]
      };
    case UPDATE_TASK_TYPE:
      return {
        ...state,
        taskTypes: state.taskTypes.map(tasType =>
          tasType.pk === action.payload ? (tasType = action.payload) : tasType
        )
      };
    default:
      return state;
  }
}
