import {ISAUTH, LOGIN} from "../remote-service/types-security";

// cada reducers tiene su propio state
const initialState = {
  auth: [],
  isAuth: false
};

// los reducers son los unicos que pueden modificar el store
export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        auth: action.payload
      };
    case ISAUTH:
      return {
        ...state,
        isAuth: action.payload
      };
    default:
      return state;
  }
}
