import {CLIENT_STATUS, TASK_INFO_BY_USER, TASK_STATUS, TICKETS_STATUS} from "../remote-service/types-dashboard";

const initialState = {
  clientStatus: [],
  ticketsStatus: [],
  taskStatus: [],
  taskInfo: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLIENT_STATUS:
      return {
        ...state,
        clientStatus: action.payload
      };
    case TICKETS_STATUS:
      return {
        ...state,
        ticketsStatus: action.payload
      };
    case TASK_STATUS:
      return {
        ...state,
        taskStatus: action.payload
      };
    case TASK_INFO_BY_USER:
      return {
        ...state,
        taskInfo: action.payload
      };
    default:
      return state;
  }
}
