import {LIST_MIN_PERMISSION, SEARCH_PERMISSION} from "../remote-service/types-security";

const initialState = {
  permissionss: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_PERMISSION:
      return {
        ...state,
        permissionss: action.payload
      };
    case LIST_MIN_PERMISSION:
      return {
        ...state,
        permissionss: action.payload
      };
    default:
      return state;
  }
}
