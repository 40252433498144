import {ADD_DEBIT, UPDATE_DEBIT} from "../remote-service/types-collection";

const initialState = {
  debits: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_DEBIT:
      return {
        ...state,
        debits: action.payload
      };
    case UPDATE_DEBIT:
      return {
        ...state,
        debits: action.payload
      };
    default:
      return state;
  }
}
