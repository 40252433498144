import {
  ADD_EQUIPMENT,
  DELETE_EQUIPMENT,
  DETAILS_EQUIPMENT,
  SEARCH_EQUIPMENT,
  TEST_CONNECTION_EQUIPMENT,
  UPDATE_EQUIPMENT
} from "../remote-service/types-infrastructure";

const initialState = {
  equipments: [],
  equipment: null,
  resultEquipmentTest: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_EQUIPMENT:
      return {
        ...state,
        equipments: action.payload
      };
    case DETAILS_EQUIPMENT:
      return {
        ...state,
        equipment: action.payload
      };
    case DELETE_EQUIPMENT:
      return {
        ...state,
        equipments: state.equipments.filter(equipment => equipment.pk !== action.payload)
      };
    case ADD_EQUIPMENT:
      return {
        ...state,
        equipments: [...state.equipments, action.payload]
      };
    case UPDATE_EQUIPMENT:
      return {
        ...state,
        equipments: state.equipments.map(equipment =>
          equipment.pk === action.payload ? (equipment = action.payload) : equipment
        )
      };
    case TEST_CONNECTION_EQUIPMENT:
      return {
        ...state,
        resultEquipmentTest: action.payload
      };
    default:
      return state;
  }
}
