import {
  ADD_TASK,
  CHANGE_STATUS_PENDING_TASK_EXPORT_XLSX_TASK,
  DELETE_TASK,
  DETAILS_TASK,
  LIST_SUPERVISED_TASK,
  PENDING_TASK_EXPORT_XLSX,
  PENDING_TASK_EXPORT_XLSX_TASK,
  SEARCH_TASK,
  SEARCH_TASK_ALL,
  SEARCH_TASK_CALENDAR,
  UPDATE_TASK
} from "../remote-service/types-task";

const initialState = {
  tasks: [],
  task: null,
  taskCalendar: [],
  taskAll: [],
  taskSupervised: [],
  pending_task_report: null,
  pending_task_report_task: null,
  status_pending_task_xlsx_task: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_TASK:
      return {
        ...state,
        tasks: action.payload
      };
    case DETAILS_TASK:
      return {
        ...state,
        task: action.payload
      };
    case DELETE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter(task => task.pk !== action.payload)
      };
    case ADD_TASK:
      return {
        ...state,
        tasks: [...state.tasks, action.payload]
      };
    case UPDATE_TASK:
      return {
        ...state,
        tasks: state.tasks.map(task =>
          task.pk === action.payload ? (task = action.payload) : task
        )
      };
    case SEARCH_TASK_CALENDAR:
      return {
        ...state,
        taskCalendar: action.payload
      };
    case SEARCH_TASK_ALL:
      return {
        ...state,
        taskAll: action.payload
      };
    case LIST_SUPERVISED_TASK:
      return {
        ...state,
        taskSupervised: action.payload
      };
    case PENDING_TASK_EXPORT_XLSX:
      return {
        ...state,
        pending_task_report: action.payload
      };
    case PENDING_TASK_EXPORT_XLSX_TASK:
      return {
        ...state,
        pending_task_report_task: action.payload
      };
    case CHANGE_STATUS_PENDING_TASK_EXPORT_XLSX_TASK:
      return {
        ...state,
        status_pending_task_xlsx_task: action.payload
      };
    default:
      return state;
  }
}
