/**
 * Types Radius
 * @type {string}
 */

export const LIST_MIN_USER_GROUPS = 'LIST_MIN_USER_GROUPS';
export const LIST_MIN_SERVICES = 'LIST_MIN_SERVICES';
export const LIST_MIN_NAS = 'LIST_MIN_NAS';
export const ARRAY_MIN_NAS = 'ARRAY_MIN_NAS';

/**
 * Types Users Radius
 * @type {string}
 */
export const SEARCH_USER_RADIUS = 'SEARCH_USER_RADIUS';
export const SEARCH_USER_RADIUS_NEXT = 'SEARCH_USER_RADIUS_NEXT';
export const SEARCH_USER_RADIUS_PREVIOUS = 'SEARCH_USER_RADIUS_PREVIOUS';
export const DETAILS_USER_RADIUS = 'DETAILS_USER_RADIUS';
export const ADD_USER_RADIUS = 'ADD_USER_RADIUS';
export const DELETE_USER_RADIUS = 'DELETE_USER_RADIUS';
export const UPDATE_USER_RADIUS = 'UPDATE_USER_RADIUS';
export const DISABLED_USER_RADIUS = 'DISABLED_USER_RADIUS';
export const ENABLED_USER_RADIUS = 'ENABLED_USER_RADIUS';
export const PASSWORD_WIFI_USER = 'PASSWORD_WIFI_USER';
export const SEARCH_RETIRED_USER_RADIUS = 'SEARCH_RETIRED_USER_RADIUS';
export const SEARCH_CHARTS_USER_RADIUS = 'SEARCH_CHARTS_USER_RADIUS';
