import {
  SEARCH_OXXO_CODE
} from "../remote-service/types-client";

const initialState = {
  oxxo_code: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_OXXO_CODE:
      return {
        ...state,
        oxxo_code: action.payload
      };
    default:
      return state;
  }
}
