import {
  ADD_BUY,
  DELETE_BUY,
  DETAILS_BUY,
  EXPORT_EXCEL_BUY,
  EXPORT_EXCEL_DETAILS_BUY,
  SEARCH_BUY_APPROVED,
  SEARCH_BUY_ARCHIVED,
  SEARCH_BUY_PURCHASED,
  SEARCH_BUY_RECEIVED,
  SEARCH_BUY_REQUESTED,
  UPDATE_BUY
} from "../remote-service/types-shopping";

const initialState = {
  buyRequested: [],
  buyApproved: [],
  buyPurchased: [],
  buyReceived: [],
  buyArchived: [],
  buy: null,
  exported_excel: false,
  exported_details_excel: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_BUY_REQUESTED:
      return {
        ...state,
        buyRequested: action.payload
      };
    case SEARCH_BUY_APPROVED:
      return {
        ...state,
        buyApproved: action.payload
      };
    case SEARCH_BUY_PURCHASED:
      return {
        ...state,
        buyPurchased: action.payload
      };
    case SEARCH_BUY_RECEIVED:
      return {
        ...state,
        buyReceived: action.payload
      };
    case SEARCH_BUY_ARCHIVED:
      return {
        ...state,
        buyArchived: action.payload
      };
    case DETAILS_BUY:
      return {
        ...state,
        buy: action.payload
      };
    case DELETE_BUY:
      return {
        ...state,
        buyRequested: state.buyRequested.filter(buy => buy.pk !== action.payload),
        buyApproved: state.buyApproved.filter(buy => buy.pk !== action.payload),
        buyPurchased: state.buyPurchased.filter(buy => buy.pk !== action.payload),
        buyReceived: state.buyReceived.filter(buy => buy.pk !== action.payload),
        buyArchived: state.buyArchived.filter(buy => buy.pk !== action.payload)
      };
    case ADD_BUY:
      return {
        ...state,
      };
    case UPDATE_BUY:
      return {
        ...state,
      };
    case EXPORT_EXCEL_BUY:
      return {
        ...state,
        exported_excel: action.payload
      };
    case EXPORT_EXCEL_DETAILS_BUY:
      return {
        ...state,
        exported_details_excel: action.payload
      };
    default:
      return state;
  }
}
