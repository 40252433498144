import {
  ADD_TICKETS_SOLUTIONS,
  DELETE_TICKETS_SOLUTIONS,
  DETAILS_TICKETS_SOLUTIONS,
  LIST_MIN_TICKETS_SOLUTIONS,
  SEARCH_TICKETS_SOLUTIONS,
  UPDATE_TICKETS_SOLUTIONS
} from "../remote-service/types-support";

const initialState = {
  ticketSolution: null,
  ticketsSolutions: [],
  ticketsSolutionsMin: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_TICKETS_SOLUTIONS:
      return {
        ...state,
        ticketsSolutions: action.payload
      };
    case DETAILS_TICKETS_SOLUTIONS:
      return {
        ...state,
        ticketSolution: action.payload
      };
    case DELETE_TICKETS_SOLUTIONS:
      return {
        ...state,
        ticketsSolutions: state.ticketsSolutions.filter(ticketsSolution => ticketsSolution.pk !== action.payload)
      };
    case ADD_TICKETS_SOLUTIONS:
      return {
        ...state,
        ticketsSolutions: [...state.ticketsSolutions, action.payload]
      };
    case UPDATE_TICKETS_SOLUTIONS:
      return {
        ...state,
        ticketsSolutions: state.ticketsSolutions.map(ticketsSolution =>
          ticketsSolution.pk === action.payload ? (ticketsSolution = action.payload) : ticketsSolution
        )
      };
    case LIST_MIN_TICKETS_SOLUTIONS:
      return {
        ...state,
        ticketsSolutionsMin: action.payload
      };
    default:
      return state;
  }
}
