import {
  ADD_ARCHIVED_NAS_TICKET,
  ADD_NAS_TICKET,
  ARCHIVED_NAS_TICKET,
  DELETE_NAS_TICKET,
  DETAILS_NAS_TICKET,
  SEARCH_NAS_TICKET,
  SEARCH_NAS_TICKET_ARCHIVED,
  SEARCH_NAS_TICKET_EARRING,
  SEARCH_NAS_TICKET_ENDED,
  SEARCH_NAS_TICKET_PROCESS,
  TICKET_CREATED_BY_NAS,
  UPDATE_NAS_TICKET
} from "../remote-service/types-support";

const initialState = {
  nas_tickets: [],
  nas_ticket: null,
  has_ticket_created: false,
  nas_tickets_earring: [],
  nas_tickets_process: [],
  nas_tickets_ended: [],
  nas_tickets_archived: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_NAS_TICKET_EARRING:
      return {
        ...state,
        nas_tickets_earring: action.payload
      };
    case SEARCH_NAS_TICKET_PROCESS:
      return {
        ...state,
        nas_tickets_process: action.payload
      };
    case SEARCH_NAS_TICKET_ENDED:
      return {
        ...state,
        nas_tickets_ended: action.payload
      };
    case SEARCH_NAS_TICKET_ARCHIVED:
      return {
        ...state,
        nas_tickets_archived: action.payload
      };
    case SEARCH_NAS_TICKET:
      return {
        ...state,
        nas_tickets: action.payload
      };
    case DETAILS_NAS_TICKET:
      return {
        ...state,
        nas_ticket: action.payload
      };
    case DELETE_NAS_TICKET:
      return {
        ...state,
        nas_tickets_earring: state.nas_tickets_earring.filter(nasTicket => nasTicket.pk !== action.payload),
      };
    case ARCHIVED_NAS_TICKET:
      return {
        ...state,
        nas_tickets_ended: state.nas_tickets_ended.filter(nasTicket => nasTicket.pk !== action.payload),
      };
    case ADD_NAS_TICKET:
      return {
        ...state,
        nas_tickets_earring: [...state.nas_tickets_earring, action.payload]
      };
    case ADD_ARCHIVED_NAS_TICKET:
      return {
        ...state,
        nas_tickets_archived: [...state.nas_tickets_archived, action.payload]
      };
    case UPDATE_NAS_TICKET:
      return {
        ...state,
        nas_tickets: state.nas_tickets.map(nasTicket =>
          nasTicket.pk === action.payload.pk ? (nasTicket = action.payload) : nasTicket
        )
      };
    case TICKET_CREATED_BY_NAS:
      return {
        ...state,
        has_nas_ticket_created: action.payload
      };
    default:
      return state;
  }
}
