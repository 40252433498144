import {DETAILS_EQUIPMENT_SNAPSHOT,} from "../remote-service/types-support";

const initialState = {
  nasInfo: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DETAILS_EQUIPMENT_SNAPSHOT:
      return {
        ...state,
        nasInfo: action.payload
      };
    default:
      return state;
  }
}
