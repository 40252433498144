/**
 * Types Equipment
 * @type {string}
 */
export const SEARCH_EQUIPMENT = 'SEARCH_EQUIPMENT';
export const DETAILS_EQUIPMENT = 'DETAILS_EQUIPMENT';
export const ADD_EQUIPMENT = 'ADD_EQUIPMENT';
export const DELETE_EQUIPMENT = 'DELETE_EQUIPMENT';
export const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT';
export const TEST_CONNECTION_EQUIPMENT = 'TEST_CONNECTION_EQUIPMENT';
