/**
 * Types Task
 * @type {string}
 */
export const SEARCH_TASK = 'SEARCH_TASK';
export const DETAILS_TASK = 'DETAILS_TASK';
export const ADD_TASK = 'ADD_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const SEARCH_TASK_CALENDAR = 'SEARCH_TASK_CALENDAR';
export const SEARCH_TASK_ALL = 'SEARCH_TASK_ALL';


/**
 * Types Task Type
 * @type {string}
 */
export const SEARCH_TASK_TYPE = 'SEARCH_TASK_TYPE';
export const DETAILS_TASK_TYPE = 'DETAILS_TASK_TYPE';
export const ADD_TASK_TYPE = 'ADD_TASK_TYPE';
export const DELETE_TASK_TYPE = 'DELETE_TASK_TYPE';
export const UPDATE_TASK_TYPE = 'UPDATE_TASK_TYPE';
export const LIST_MIN_TASK_TYPE = 'LIST_MIN_TASK_TYPE';
export const LIST_SUPERVISED_TASK = 'LIST_SUPERVISED_TASK';

export const PENDING_TASK_EXPORT_XLSX = 'PENDING_TASK_EXPORT_XLSX';
export const PENDING_TASK_EXPORT_XLSX_TASK = 'PENDING_TASK_EXPORT_XLSX_TASK';
export const CHANGE_STATUS_PENDING_TASK_EXPORT_XLSX_TASK = 'CHANGE_STATUS_PENDING_TASK_EXPORT_XLSX_TASK';

