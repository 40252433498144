import {LIST_MIN_SERVICE_STATUS} from '../remote-service/types-client'

const initialState = {
  serviceStatus: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_MIN_SERVICE_STATUS:
      return {
        ...state,
        serviceStatus: action.payload
      };
    default:
      return state;
  }
}
