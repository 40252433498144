/**
 * Types Tickets
 * @type {string}
 */
export const SEARCH_TICKET_ALL= 'SEARCH_TICKET_ALL';
export const SEARCH_TICKET_NOT_SUPERVISOR = 'SEARCH_TICKET_NOT_SUPERVISOR';
export const SEARCH_TICKET_MINE = 'SEARCH_TICKET_MINE';
export const SEARCH_TICKET_NAS = 'SEARCH_TICKET_NAS';
export const SEARCH_TICKET_ENDED = 'SEARCH_TICKET_ENDED';
export const DETAILS_TICKET = 'DETAILS_TICKET';
export const DELETE_TICKET_NOT_SUPERVISOR = 'DELETE_TICKET_NOT_SUPERVISOR';
export const UPDATE_TICKET_NOT_SUPERVISOR = 'UPDATE_TICKET_NOT_SUPERVISOR';
export const ADD_TICKET_NOT_SUPERVISOR = 'ADD_TICKET_NOT_SUPERVISOR';
export const DELETE_TICKET_MINE = 'DELETE_TICKET_NOT_MINE';
export const UPDATE_TICKET_MINE = 'UPDATE_TICKET_NOT_MINE';
export const ADD_TICKET_MINE = 'ADD_TICKET_MINE';
export const TICKET_CREATED_BY_CLIENT = 'TICKET_CREATED_BY_CLIENT';
export const DELETE_TICKET_ENDED = 'DELETE_TICKET_ENDED';
export const SEARCH_TICKET_SUPERVISOR = 'SEARCH_TICKET_SUPERVISOR';
export const SEARCH_TICKET_COORDINATOR_VISIT_SLOPES = 'SEARCH_TICKET_COORDINATOR_VISIT_SLOPES';
export const SEARCH_TICKET_COORDINATOR_VISIT_WITH_TECHNICAL = 'SEARCH_TICKET_COORDINATOR_VISIT_WITH_TECHNICAL';
export const DELETE_TICKET_COORDINATOR_VISIT = 'DELETE_TICKET_COORDINATOR_VISIT';

export const SEARCH_TICKET_TECHNICAL_NOT_SUPERVISOR = 'SEARCH_TICKET_TECHNICAL_NOT_SUPERVISOR';
export const SEARCH_TICKET_TECHNICAL_MINE = 'SEARCH_TICKET_TECHNICAL_MINE';
export const ADD_TICKET_TECHNICAL_NOT_SUPERVISOR = 'ADD_TICKET_TECHNICAL_NOT_SUPERVISOR';
export const ADD_TICKET_TECHNICAL_MINE = 'ADD_TICKET_TECHNICAL_MINE';
export const DELETE_TICKET_TECHNICAL_NOT_SUPERVISOR = 'DELETE_TICKET_TECHNICAL_NOT_SUPERVISOR';
export const DELETE_TICKET_TECHNICAL_MINE = 'DELETE_TICKET_TECHNICAL_MINE';

/**
 * Types Technical
 * @type {string}
 */
export const LIST_MIN_TECHNICAL = 'LIST_MIN_TECHNICAL';
export const SEARCH_TECHNICAL = 'SEARCH_TECHNICAL';
export const ADD_TECHNICAL = 'ADD_TECHNICAL';
export const DELETE_TECHNICAL = 'DELETE_TECHNICAL';
export const UPDATE_TECHNICAL = 'UPDATE_TECHNICAL';
export const DETAILS_TECHNICAL = 'DETAILS_TECHNICAL';
export const LIST_MIN_TECHNICAL_CREATED = 'LIST_MIN_TECHNICAL_CREATED';

/**
 * Types Ticket type
 * @type {string}
 */
export const LIST_MIN_TICKET_TYPE = 'LIST_MIN_TICKET_TYPE';


/**
 * Types Tickets Problems
 * @type {string}
 */
export const SEARCH_TICKETS_PROBLEMS = 'SEARCH_TICKETS_PROBLEMS';
export const DETAILS_TICKETS_PROBLEMS = 'DETAILS_TICKETS_PROBLEMS';
export const ADD_TICKETS_PROBLEMS = 'ADD_TICKETS_PROBLEMS';
export const DELETE_TICKETS_PROBLEMS = 'DELETE_TICKETS_PROBLEMS';
export const UPDATE_TICKETS_PROBLEMS = 'UPDATE_TICKETS_PROBLEMS';
export const LIST_MIN_TICKETS_PROBLEMS = 'LIST_MIN_TICKETS_PROBLEMS';


/**
 * Types Tickets Solutions
 * @type {string}
 */
export const SEARCH_TICKETS_SOLUTIONS = 'SEARCH_TICKETS_SOLUTIONS';
export const DETAILS_TICKETS_SOLUTIONS = 'DETAILS_TICKETS_SOLUTIONS';
export const ADD_TICKETS_SOLUTIONS = 'ADD_TICKETS_SOLUTIONS';
export const DELETE_TICKETS_SOLUTIONS = 'DELETE_TICKETS_SOLUTIONS';
export const UPDATE_TICKETS_SOLUTIONS = 'UPDATE_TICKETS_SOLUTIONS';
export const LIST_MIN_TICKETS_SOLUTIONS = 'LIST_MIN_TICKETS_SOLUTIONS';

export const DETAILS_EQUIPMENT_SNAPSHOT = 'DETAILS_EQUIPMENT_SNAPSHOT';


/**
 * Types Nas Tickets Problems
 * @type {string}
 */
export const SEARCH_NAS_TICKETS_PROBLEMS = 'SEARCH_NAS_TICKETS_PROBLEMS';
export const DETAILS_NAS_TICKETS_PROBLEMS = 'DETAILS_NAS_TICKETS_PROBLEMS';
export const ADD_NAS_TICKETS_PROBLEMS = 'ADD_NAS_TICKETS_PROBLEMS';
export const DELETE_NAS_TICKETS_PROBLEMS = 'DELETE_NAS_TICKETS_PROBLEMS';
export const UPDATE_NAS_TICKETS_PROBLEMS = 'UPDATE_NAS_TICKETS_PROBLEMS';
export const LIST_MIN_NAS_TICKETS_PROBLEMS = 'LIST_MIN_NAS_TICKETS_PROBLEMS';


/**
 * Types Nas Tickets Solutions
 * @type {string}
 */
export const SEARCH_NAS_TICKETS_SOLUTIONS = 'SEARCH_NAS_TICKETS_SOLUTIONS';
export const DETAILS_NAS_TICKETS_SOLUTIONS = 'DETAILS_NAS_TICKETS_SOLUTIONS';
export const ADD_NAS_TICKETS_SOLUTIONS = 'ADD_NAS_TICKETS_SOLUTIONS';
export const DELETE_NAS_TICKETS_SOLUTIONS = 'DELETE_NAS_TICKETS_SOLUTIONS';
export const UPDATE_NAS_TICKETS_SOLUTIONS = 'UPDATE_NAS_TICKETS_SOLUTIONS';
export const LIST_MIN_NAS_TICKETS_SOLUTIONS = 'LIST_MIN_NAS_TICKETS_SOLUTIONS';


/**
 * Types Nas Tickets
 * @type {string}
 */
export const SEARCH_NAS_TICKET = 'SEARCH_NAS_TICKET';
export const DETAILS_NAS_TICKET = 'DETAILS_NAS_TICKET';
export const DELETE_NAS_TICKET = 'DELETE_NAS_TICKET';
export const UPDATE_NAS_TICKET = 'UPDATE_NAS_TICKET';
export const ADD_NAS_TICKET = 'ADD_NAS_TICKET';
export const TICKET_CREATED_BY_NAS = 'TICKET_CREATED_BY_NAS';

export const SEARCH_NAS_TICKET_EARRING = 'SEARCH_NAS_TICKET_EARRING';
export const SEARCH_NAS_TICKET_PROCESS = 'SEARCH_NAS_TICKET_PROCESS';
export const SEARCH_NAS_TICKET_ENDED = 'SEARCH_NAS_TICKET_ENDED';
export const SEARCH_NAS_TICKET_ARCHIVED = 'SEARCH_NAS_TICKET_ARCHIVED';
export const ARCHIVED_NAS_TICKET = 'ARCHIVED_NAS_TICKET';
export const ADD_ARCHIVED_NAS_TICKET = 'ADD_ARCHIVED_NAS_TICKET';
