import {
  ADD_TICKETS_PROBLEMS,
  DELETE_TICKETS_PROBLEMS,
  DETAILS_TICKETS_PROBLEMS,
  LIST_MIN_TICKETS_PROBLEMS,
  SEARCH_TICKETS_PROBLEMS,
  UPDATE_TICKETS_PROBLEMS
} from "../remote-service/types-support";

const initialState = {
  ticketProblem: null,
  ticketsProblems: [],
  ticketsProblemsMin: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_TICKETS_PROBLEMS:
      return {
        ...state,
        ticketsProblems: action.payload
      };
    case DETAILS_TICKETS_PROBLEMS:
      return {
        ...state,
        ticketProblem: action.payload
      };
    case DELETE_TICKETS_PROBLEMS:
      return {
        ...state,
        ticketsProblems: state.ticketsProblems.filter(ticketsProblem => ticketsProblem.pk !== action.payload)
      };
    case ADD_TICKETS_PROBLEMS:
      return {
        ...state,
        ticketsProblems: [...state.ticketsProblems, action.payload]
      };
    case UPDATE_TICKETS_PROBLEMS:
      return {
        ...state,
        ticketsProblems: state.ticketsProblems.map(ticketsProblem =>
          ticketsProblem.pk === action.payload ? (ticketsProblem = action.payload) : ticketsProblem
        )
      };
    case LIST_MIN_TICKETS_PROBLEMS:
      return {
        ...state,
        ticketsProblemsMin: action.payload
      };
    default:
      return state;
  }
}
