import {
  ADD_ROLE,
  DELETE_ROLE,
  DETAILS_ROLE,
  LIST_MIN_ROLE,
  SEARCH_ROLE,
  UPDATE_ROLE
} from "../remote-service/types-security";

const initialState = {
  roles: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_ROLE:
      return {
        ...state,
        roles: action.payload
      };
    case DETAILS_ROLE:
      return {
        ...state,
        role: action.payload
      };
    case DELETE_ROLE:
      return {
        ...state,
        roles: state.roles.filter(role => role.pk !== action.payload)
      };
    case ADD_ROLE:
      return {
        ...state,
        roles: [...state.roles, action.payload]
      };
    case UPDATE_ROLE:
      return {
        ...state,
        roles: state.roles.map(role =>
          role.pk === action.payload ? (role = action.payload) : role
        )
      };
    case LIST_MIN_ROLE:
      return {
        ...state,
        roles: action.payload
      };
    default:
      return state;
  }
}
