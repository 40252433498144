import {
  ADD_ZONE,
  DELETE_ZONE,
  DETAILS_ZONE,
  LIST_MIN_ZONE,
  SEARCH_ZONE,
  UPDATE_ZONE
} from "../remote-service/types-client";

const initialState = {
  zones: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_ZONE:
      return {
        ...state,
        zones: action.payload
      };
    case DETAILS_ZONE:
      return {
        ...state,
        zone: action.payload
      };
    case DELETE_ZONE:
      return {
        ...state,
        zones: state.zones.filter(zone => zone.pk !== action.payload)
      };
    case ADD_ZONE:
      return {
        ...state,
        zones: [...state.zones, action.payload]
      };
    case UPDATE_ZONE:
      return {
        ...state,
        zones: state.zones.map(zone =>
          zone.pk === action.payload ? (zone = action.payload) : zone
        )
      };
    case LIST_MIN_ZONE:
      return {
        ...state,
        zones: action.payload
      };
    default:
      return state;
  }
}
