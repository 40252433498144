import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  DETAILS_PRODUCT,
  LIST_MIN_PRODUCT,
  SEARCH_PRODUCT,
  UPDATE_PRODUCT
} from "../remote-service/types-shopping";

const initialState = {
  productMin: [],
  products: [],
  product: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_MIN_PRODUCT:
      return {
        ...state,
        productMin: action.payload
      };
    case SEARCH_PRODUCT:
      return {
        ...state,
        products: action.payload
      };
    case DETAILS_PRODUCT:
      return {
        ...state,
        product: action.payload
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter(product => product.pk !== action.payload)
      };
    case ADD_PRODUCT:
      return {
        ...state,
        products: [...state.products, action.payload]
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        products: state.products.map(product =>
          product.pk === action.payload ? (product = action.payload) : product
        )
      };
    default:
      return state;
  }
}
