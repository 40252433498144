import {
  ADD_PROVIDER,
  DELETE_PROVIDER,
  DETAILS_PROVIDER,
  LIST_MIN_PROVIDER,
  SEARCH_PROVIDER,
  UPDATE_PROVIDER
} from "../remote-service/types-shopping";

const initialState = {
  providerMin: [],
  providers: [],
  provider: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_MIN_PROVIDER:
      return {
        ...state,
        providerMin: action.payload
      };
    case SEARCH_PROVIDER:
      return {
        ...state,
        providers: action.payload
      };
    case DETAILS_PROVIDER:
      return {
        ...state,
        provider: action.payload
      };
    case DELETE_PROVIDER:
      return {
        ...state,
        providers: state.providers.filter(provider => provider.pk !== action.payload)
      };
    case ADD_PROVIDER:
      return {
        ...state,
        providers: [...state.providers, action.payload]
      };
    case UPDATE_PROVIDER:
      return {
        ...state,
        providers: state.providers.map(provider =>
          provider.pk === action.payload ? (provider = action.payload) : provider
        )
      };
    default:
      return state;
  }
}
