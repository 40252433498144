/**
 * Types information
 * @type {string}
 */
export const INFORMATION = 'INFORMATION';

export const INFORMATION_EXPORT_XLSX = 'INFORMATION_EXPORT_XLSX';
export const INFORMATION_EXPORT_XLSX_TASK = 'INFORMATION_EXPORT_XLSX_TASK';
export const CHANGE_STATUS_INFORMATION_EXPORT_XLSX_TASK = 'CHANGE_STATUS_INFORMATION_EXPORT_XLSX_TASK';

export const DEBTORS_EXPORT_XLSX = 'DEBTORS_EXPORT_XLSX';
export const DEBTORS_EXPORT_XLSX_TASK = 'DEBTORS_EXPORT_XLSX_TASK';
export const CHANGE_STATUS_DEBTORS_EXPORT_XLSX_TASK = 'CHANGE_STATUS_DEBTORS_EXPORT_XLSX_TASK';

export const LIST_XLSX_EXPORTED = 'LIST_XLSX_EXPORTED';

export const INFORMATION_TICKETS = 'INFORMATION_TICKETS';
export const INFORMATION_TICKETS_EXPORT_XLSX = 'INFORMATION_TICKETS_EXPORT_XLSX';
export const INFORMATION_TICKETS_EXPORT_XLSX_TASK = 'INFORMATION_TICKETS_EXPORT_XLSX_TASK';
export const CHANGE_STATUS_INFORMATION_TICKETS_EXPORT_XLSX_TASK = 'CHANGE_STATUS_INFORMATION_TICKETS_EXPORT_XLSX_TASK';

export const INFORMATION_TASK = 'INFORMATION_TASK';
