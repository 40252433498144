import {LIST_MIN_ZONE_CATEGORY} from "../remote-service/types-client";

const initialState = {
  zone_category: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_MIN_ZONE_CATEGORY:
      return {
        ...state,
        zone_category: action.payload
      };
    default:
      return state;
  }
}
