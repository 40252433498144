import {
  ADD_TECHNICAL,
  DELETE_TECHNICAL,
  LIST_MIN_TECHNICAL,
  SEARCH_TECHNICAL,
  UPDATE_TECHNICAL,
  DETAILS_TECHNICAL, LIST_MIN_TECHNICAL_CREATED
} from "../remote-service/types-support";

const initialState = {
  technicals: [],
  technical: null,
  technicals_created: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_TECHNICAL:
      return {
        ...state,
        technicals: action.payload
      };
    case DELETE_TECHNICAL:
      return {
        ...state,
        technicals: state.technicals.filter(technical => technical.pk !== action.payload)
      };
    case ADD_TECHNICAL:
      return {
        ...state,
        technicals: [...state.technicals, action.payload]
      };
    case UPDATE_TECHNICAL:
      return {
        ...state,
        technicals: state.technicals.map(technical =>
          technical.pk === action.payload ? (technical = action.payload) : technical
        )
      };
    case LIST_MIN_TECHNICAL:
      return {
        ...state,
        technicals: action.payload
      };
    case LIST_MIN_TECHNICAL_CREATED:
      return {
        ...state,
        technicals_created: action.payload
      };
    case DETAILS_TECHNICAL:
      return {
        ...state,
        technical: action.payload
      };
    default:
      return state;
  }
}
