import {
  ADD_CREDIT,
  DELETE_FILE_IMPORTED_CREDIT,
  LIST_MIN_CREDIT,
  SEARCH_FILE_IMPORTED_CREDIT,
  UPDATE_CREDIT
} from "../remote-service/types-collection";

const initialState = {
  credits: null,
  min_credits: [],
  file_imported_credits: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_CREDIT:
      return {
        ...state,
        credits: action.payload
      };
    case UPDATE_CREDIT:
      return {
        ...state,
        credits: action.payload
      };
    case LIST_MIN_CREDIT:
      return {
        ...state,
        min_credits: action.payload
      };
    case SEARCH_FILE_IMPORTED_CREDIT:
      return {
        ...state,
        file_imported_credits: action.payload
      };
    case DELETE_FILE_IMPORTED_CREDIT:
      return {
        ...state,
        file_imported_credits: state.file_imported_credits.filter(file => file.pk !== action.payload)
      };
    default:
      return state;
  }
}
