/**
 * Types Way to Pay
 * @type {string}
 */
export const LIST_MIN_WAY_PAY = 'LIST_MIN_WAY_PAY';

/**
 * Types Status Service
 * @type {string}
 */
export const LIST_MIN_SERVICE_STATUS = 'LIST_MIN_SERVICE_STATUS';


/**
 * Types Digital Tax Receipt Online
 * @type {string}
 */
export const LIST_MIN_DIGITAL_TAX_RECEIPT_ONLINE = 'LIST_MIN_DIGITAL_TAX_RECEIPT_ONLINE';

/**
 * Types Client
 * @type {string}
 */
export const SEARCH_CLIENT = 'SEARCH_CLIENT';
export const SEARCH_CLIENT_NEXT = 'SEARCH_CLIENT_NEXT';
export const SEARCH_CLIENT_PREVIOUS = 'SEARCH_CLIENT_PREVIOUS';
export const DETAILS_CLIENT = 'DETAILS_CLIENT';
export const ADD_CLIENT = 'ADD_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const SYNC_CLIENT_RADIUS = 'SYNC_CLIENT_RADIUS';
export const DISABLED_CLIENTS = 'DISABLED_CLIENTS';
export const ENABLED_CLIENTS = 'ENABLED_CLIENTS';
export const LIST_MIN_CLIENTS = 'LIST_MIN_CLIENTS';
export const UPDATING_MONITORING_CLIENTS = 'UPDATING_MONITORING_CLIENTS';
export const UPDATING_RETIRE_CLIENTS = 'UPDATING_RETIRE_CLIENTS';
export const UPDATING_DOMICILED_CLIENTS = 'UPDATING_DOMICILED_CLIENTS';
export const SEARCH_LOGS_CLIENT = 'SEARCH_LOGS_CLIENT';



/**
 * Types Reference Person
 * @type {string}
 */
export const DELETE_REFERENCE_PERSON = 'DELETE_REFERENCE_PERSON';

/**
 * Types Contact
 * @type {string}
 */
export const DELETE_CONTACT = 'DELETE_CONTACT';

/**
 * Types Zone
 * @type {string}
 */
export const SEARCH_ZONE = 'SEARCH_ZONE ';
export const DETAILS_ZONE = 'DETAILS_ZONE ';
export const ADD_ZONE = 'ADD_ZONE ';
export const DELETE_ZONE = 'DELETE_ZONE ';
export const UPDATE_ZONE = 'UPDATE_ZONE ';
export const LIST_MIN_ZONE = 'LIST_MIN_ZONE ';


/**
 * Types Zone Category
 * @type {string}
 */
export const LIST_MIN_ZONE_CATEGORY = 'LIST_MIN_ZONE_CATEGORY';

/**
 * Business Clients Groups
 * @type {string}
 */
export const SEARCH_BUSINESS_CLIENT_GROUPS = 'SEARCH_CLIENT_GROUPS';
export const DETAILS_BUSINESS_CLIENT_GROUPS = 'DETAILS_BUSINESS_CLIENT_GROUPS';
export const ADD_BUSINESS_CLIENT_GROUPS = 'ADD_BUSINESS_CLIENT_GROUPS';
export const DELETE_BUSINESS_CLIENT_GROUPS = 'DELETE_BUSINESS_CLIENT_GROUPS';
export const UPDATE_BUSINESS_CLIENT_GROUPS = 'UPDATE_BUSINESS_CLIENT_GROUPS';


/**
 * Types Oxxo Code
 * @type {string}
 */
export const SEARCH_OXXO_CODE = 'SEARCH_OXXO_CODE ';


/**
 * Types Client Trash
 * @type {string}
 */
export const SEARCH_CLIENT_TRASH = 'SEARCH_CLIENT_TRASH';
