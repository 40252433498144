import {
  ADD_NAS_TICKETS_PROBLEMS,
  DELETE_NAS_TICKETS_PROBLEMS,
  DETAILS_NAS_TICKETS_PROBLEMS,
  LIST_MIN_NAS_TICKETS_PROBLEMS,
  SEARCH_NAS_TICKETS_PROBLEMS,
  UPDATE_NAS_TICKETS_PROBLEMS
} from "../remote-service/types-support";

const initialState = {
  nasTicketProblem: null,
  nasTicketsProblems: [],
  nasTicketsProblemsMin: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_NAS_TICKETS_PROBLEMS:
      return {
        ...state,
        nasTicketsProblems: action.payload
      };
    case DETAILS_NAS_TICKETS_PROBLEMS:
      return {
        ...state,
        nasTicketProblem: action.payload
      };
    case DELETE_NAS_TICKETS_PROBLEMS:
      return {
        ...state,
        nasTicketsProblems: state.nasTicketsProblems.filter(nasTicketsProblem => nasTicketsProblem.pk !== action.payload)
      };
    case ADD_NAS_TICKETS_PROBLEMS:
      return {
        ...state,
        nasTicketsProblems: [...state.nasTicketsProblems, action.payload]
      };
    case UPDATE_NAS_TICKETS_PROBLEMS:
      return {
        ...state,
        nasTicketsProblems: state.nasTicketsProblems.map(nasTicketsProblem =>
          nasTicketsProblem.pk === action.payload ? (nasTicketsProblem = action.payload) : nasTicketsProblem
        )
      };
    case LIST_MIN_NAS_TICKETS_PROBLEMS:
      return {
        ...state,
        nasTicketsProblemsMin: action.payload
      };
    default:
      return state;
  }
}
