import {DELETE_CONTACT} from '../remote-service/types-client'

const initialState = {
  contacts: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELETE_CONTACT:
      return {
        ...state,
        contacts: action.payload
      };
    default:
      return state;
  }
}
