import {
  ADD_NAS_TICKETS_SOLUTIONS,
  DELETE_NAS_TICKETS_SOLUTIONS,
  DETAILS_NAS_TICKETS_SOLUTIONS,
  LIST_MIN_NAS_TICKETS_SOLUTIONS,
  SEARCH_NAS_TICKETS_SOLUTIONS,
  UPDATE_NAS_TICKETS_SOLUTIONS
} from "../remote-service/types-support";

const initialState = {
  nasTicketSolution: null,
  nasTicketsSolutions: [],
  nasTicketsSolutionsMin: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_NAS_TICKETS_SOLUTIONS:
      return {
        ...state,
        nasTicketsSolutions: action.payload
      };
    case DETAILS_NAS_TICKETS_SOLUTIONS:
      return {
        ...state,
        nasTicketSolution: action.payload
      };
    case DELETE_NAS_TICKETS_SOLUTIONS:
      return {
        ...state,
        nasTicketsSolutions: state.nasTicketsSolutions.filter(nasTicketsSolution => nasTicketsSolution.pk !== action.payload)
      };
    case ADD_NAS_TICKETS_SOLUTIONS:
      return {
        ...state,
        nasTicketsSolutions: [...state.nasTicketsSolutions, action.payload]
      };
    case UPDATE_NAS_TICKETS_SOLUTIONS:
      return {
        ...state,
        nasTicketsSolutions: state.nasTicketsSolutions.map(nasTicketsSolution =>
          nasTicketsSolution.pk === action.payload ? (nasTicketsSolution = action.payload) : nasTicketsSolution
        )
      };
    case LIST_MIN_NAS_TICKETS_SOLUTIONS:
      return {
        ...state,
        nasTicketsSolutionsMin: action.payload
      };
    default:
      return state;
  }
}
