import {LIST_MIN_TICKET_TYPE} from "../remote-service/types-support";

const initialState = {
  ticketTypes: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_MIN_TICKET_TYPE:
      return {
        ...state,
        ticketTypes: action.payload
      };
    default:
      return state;
  }
}
