import {LIST_MIN_DIGITAL_TAX_RECEIPT_ONLINE} from '../remote-service/types-client'

const initialState = {
  DTROs: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_MIN_DIGITAL_TAX_RECEIPT_ONLINE:
      return {
        ...state,
        DTROs: action.payload
      };
    default:
      return state;
  }
}
