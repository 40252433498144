import {
  EXECUTED_MAKE_DEBIT,
  SEARCH_CONFIGURATION_CUT_SERVICE,
  SEARCH_CONFIGURATION_MAKE_DEBIT
} from "../remote-service/types-collection";

const initialState = {
  configurationCutService: {cut_day: null, cut_automatic_make: null},
  configurationsMakeDebit: {debit_internet_day: null, debit_automatic_make: null},
  resultMakeDebit: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_CONFIGURATION_CUT_SERVICE:
      return {
        ...state,
        configurationCutService: action.payload
      };
    case SEARCH_CONFIGURATION_MAKE_DEBIT:
      return {
        ...state,
        configurationsMakeDebit: action.payload
      };
    case EXECUTED_MAKE_DEBIT:
      return {
        ...state,
        resultMakeDebit: action.payload
      };
    default:
      return state;
  }
}
