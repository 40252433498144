import {DETAILS_USER_CREDENTIAL_MOBILE} from "../remote-service/types-mobile";

const initialState = {
  userMobile: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DETAILS_USER_CREDENTIAL_MOBILE:
      return {
        ...state,
        userMobile: action.payload
      };
    default:
      return state;
  }
}
