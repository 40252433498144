import {LIST_MIN_TYPE_CREDIT, LIST_MIN_TYPE_DEBIT} from '../remote-service/types-collection'

const initialState = {
  typeDebit: [],
  typeCredit: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_MIN_TYPE_CREDIT:
      return {
        ...state,
        typeCredit: action.payload
      };
    case LIST_MIN_TYPE_DEBIT:
      return {
        ...state,
        typeDebit: action.payload
      };
    default:
      return state;
  }
}
