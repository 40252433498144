import {
  ADD_USER_RADIUS,
  ARRAY_MIN_NAS,
  DELETE_USER_RADIUS,
  DETAILS_USER_RADIUS,
  DISABLED_USER_RADIUS,
  ENABLED_USER_RADIUS,
  LIST_MIN_NAS,
  LIST_MIN_SERVICES,
  LIST_MIN_USER_GROUPS,
  PASSWORD_WIFI_USER,
  SEARCH_CHARTS_USER_RADIUS,
  SEARCH_RETIRED_USER_RADIUS,
  SEARCH_USER_RADIUS,
  SEARCH_USER_RADIUS_NEXT,
  SEARCH_USER_RADIUS_PREVIOUS,
  UPDATE_USER_RADIUS
} from "../remote-service/types-radius";

const initialState = {
  usergroups: [],
  servicesr: [],
  usersRadius: [],
  userRadius: null,
  userRadiusCharts: [],
  nextPage: null,
  previousPage: null,
  disabledUsersRadius: [],
  enabledUsersRadius: [],
  passwordWifi: null,
  retiredUsersRadius: [],
  nas: [],
  arrayNas: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_MIN_USER_GROUPS:
      return {
        ...state,
        usergroups: action.payload
      };
    case LIST_MIN_SERVICES:
      return {
        ...state,
        servicesr: action.payload
      };
    case SEARCH_USER_RADIUS:
      return {
        ...state,
        usersRadius: action.payload
      };
    case SEARCH_USER_RADIUS_NEXT:
      return {
        ...state,
        nextPage: action.payload
      };
    case SEARCH_USER_RADIUS_PREVIOUS:
      return {
        ...state,
        previousPage: action.payload
      };
    case DETAILS_USER_RADIUS:
      return {
        ...state,
        userRadius: action.payload
      };
    case DELETE_USER_RADIUS:
      return {
        ...state,
        usersRadius: state.usersRadius.filter(client => client.username !== action.payload)
      };
    case ADD_USER_RADIUS:
      return {
        ...state,
        usersRadius: [...state.usersRadius, action.payload]
      };
    case UPDATE_USER_RADIUS:
      return {
        ...state,
        usersRadius: state.usersRadius.map(client =>
          client.username === action.payload.username ? (client = action.payload) : client
        )
      };
    case DISABLED_USER_RADIUS:
      return {
        ...state,
        disabledUsersRadius: action.payload
      };
    case ENABLED_USER_RADIUS:
      return {
        ...state,
        enabledUsersRadius: action.payload
      };
    case PASSWORD_WIFI_USER:
      return {
        ...state,
        passwordWifi: action.payload
      };
    case SEARCH_RETIRED_USER_RADIUS:
      return {
        ...state,
        retiredUsersRadius: action.payload
      };
    case SEARCH_CHARTS_USER_RADIUS:
      return {
        ...state,
        userRadiusCharts: action.payload
      };
    case LIST_MIN_NAS:
      return {
        ...state,
        nas: action.payload
      };
    case ARRAY_MIN_NAS:
      return {
        ...state,
        arrayNas: action.payload
      };
    default:
      return state;
  }
}
