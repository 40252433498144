import axios from "axios";
import {cleanAuthToLocalStorage, getAuthToLocalStorage} from "./apps/security/hooks/user-local-storage-state-hooks";
import React from "react";

export const InterceptorsResponse = () => {

  axios.interceptors.response.use(
    function (successRes) {
      return successRes;
    },
    function (error) {
      if (error.response.status.toString() === '401') {
        cleanAuthToLocalStorage();
      }

      if (error.response) {
        // Request made and server responded
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        throw Error(error.response.status + ": " + error.response.data.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request.code);
        throw Error(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        throw Error(error.message);
      }
    }
  );

  axios.interceptors.request.use(
    function (successRes) {
      getAuthToLocalStorage();
      return successRes
    }
  )
};
