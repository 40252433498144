/**
 * Types Provider
 * @type {string}
 */
export const SEARCH_PROVIDER = 'SEARCH_TASK';
export const DETAILS_PROVIDER = 'DETAILS_TASK';
export const ADD_PROVIDER = 'ADD_TASK';
export const DELETE_PROVIDER = 'DELETE_TASK';
export const UPDATE_PROVIDER = 'UPDATE_TASK';
export const LIST_MIN_PROVIDER = 'LIST_MIN_PROVIDER';

/**
 * Types Products
 * @type {string}
 */
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const DETAILS_PRODUCT = 'DETAILS_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const LIST_MIN_PRODUCT = 'LIST_MIN_PRODUCT';

/**
 * Types Buy
 * @type {string}
 */
export const SEARCH_BUY_REQUESTED = 'SEARCH_BUY_REQUESTED';
export const SEARCH_BUY_APPROVED = 'SEARCH_BUY_APPROVED';
export const SEARCH_BUY_PURCHASED = 'SEARCH_BUY_PURCHASED';
export const SEARCH_BUY_RECEIVED = 'SEARCH_BUY_RECEIVED';
export const SEARCH_BUY_ARCHIVED = 'SEARCH_BUY_ARCHIVED';
export const DETAILS_BUY = 'DETAILS_BUY';
export const ADD_BUY = 'ADD_BUY';
export const DELETE_BUY = 'DELETE_BUY';
export const UPDATE_BUY = 'UPDATE_BUY';
export const EXPORT_EXCEL_BUY = 'EXPORT_EXCEL_BUY';
export const EXPORT_EXCEL_DETAILS_BUY = 'EXPORT_EXCEL_DETAILS_BUY';

/**
 * Types Kit
 * @type {string}
 */
export const SEARCH_KIT = 'SEARCH_KIT';
export const DETAILS_KIT = 'DETAILS_KIT';
export const ADD_KIT = 'ADD_KIT';
export const DELETE_KIT = 'DELETE_KIT';
export const UPDATE_KIT = 'UPDATE_KIT';
export const LIST_MIN_KIT = 'LIST_MIN_KIT';

