import {
  ADD_TICKET_MINE,
  ADD_TICKET_NOT_SUPERVISOR,
  ADD_TICKET_TECHNICAL_MINE,
  ADD_TICKET_TECHNICAL_NOT_SUPERVISOR,
  DELETE_TICKET_COORDINATOR_VISIT,
  DELETE_TICKET_ENDED,
  DELETE_TICKET_MINE,
  DELETE_TICKET_NOT_SUPERVISOR,
  DELETE_TICKET_TECHNICAL_MINE,
  DELETE_TICKET_TECHNICAL_NOT_SUPERVISOR,
  DETAILS_TICKET,
  SEARCH_TICKET_ALL,
  SEARCH_TICKET_COORDINATOR_VISIT_SLOPES,
  SEARCH_TICKET_COORDINATOR_VISIT_WITH_TECHNICAL,
  SEARCH_TICKET_ENDED,
  SEARCH_TICKET_MINE,
  SEARCH_TICKET_NAS,
  SEARCH_TICKET_NOT_SUPERVISOR,
  SEARCH_TICKET_SUPERVISOR,
  SEARCH_TICKET_TECHNICAL_MINE,
  SEARCH_TICKET_TECHNICAL_NOT_SUPERVISOR,
  TICKET_CREATED_BY_CLIENT,
  UPDATE_TICKET_MINE,
  UPDATE_TICKET_NOT_SUPERVISOR
} from "../remote-service/types-support";

const initialState = {
  tickets_not_supervisor: [],
  tickets_mine: [],
  tickets_ended: [],
  tickets_all: [],
  tickets_nas: [],
  tickets_technical_not_supervisor: [],
  tickets_technical_mine: [],
  ticket: null,
  has_ticket_created: false,
  tickets_supervisor: [],
  tickets_coordinator_visit_slopes: [],
  tickets_coordinator_visit_with_technical: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_TICKET_SUPERVISOR:
      return {
        ...state,
        tickets_supervisor: action.payload
      };
    case SEARCH_TICKET_COORDINATOR_VISIT_SLOPES:
      return {
        ...state,
        tickets_coordinator_visit_slopes: action.payload
      };
    case SEARCH_TICKET_COORDINATOR_VISIT_WITH_TECHNICAL:
      return {
        ...state,
        tickets_coordinator_visit_with_technical: action.payload
      };
    case SEARCH_TICKET_ALL:
      return {
        ...state,
        tickets_all: action.payload
      };
    case SEARCH_TICKET_NOT_SUPERVISOR:
      return {
        ...state,
        tickets_not_supervisor: action.payload
      };
    case SEARCH_TICKET_MINE:
      return {
        ...state,
        tickets_mine: action.payload
      };
    case SEARCH_TICKET_NAS:
      return {
        ...state,
        tickets_nas: action.payload
      };
    case SEARCH_TICKET_ENDED:
      return {
        ...state,
        tickets_ended: action.payload
      };
    case DETAILS_TICKET:
      return {
        ...state,
        ticket: action.payload
      };
    case DELETE_TICKET_NOT_SUPERVISOR:
      return {
        ...state,
        tickets_not_supervisor: state.tickets_not_supervisor.filter(ticket => ticket.pk !== action.payload)
      };
    case DELETE_TICKET_MINE:
      return {
        ...state,
        tickets_mine: state.tickets_mine.filter(ticket => ticket.pk !== action.payload)
      };
    case DELETE_TICKET_COORDINATOR_VISIT:
      return {
        ...state,
        tickets_coordinator_visit_slopes: state.tickets_coordinator_visit_slopes.filter(ticket => ticket.pk !== action.payload),
        tickets_coordinator_visit_with_technical: state.tickets_coordinator_visit_with_technical.filter(ticket => ticket.pk !== action.payload)
      };
    case ADD_TICKET_NOT_SUPERVISOR:
      return {
        ...state,
        tickets_not_supervisor: [...state.tickets_not_supervisor, action.payload]
      };
    case ADD_TICKET_MINE:
      return {
        ...state,
        tickets_mine: [...state.tickets_mine, action.payload]
      };
    case UPDATE_TICKET_NOT_SUPERVISOR:
      return {
        ...state,
        tickets_not_supervisor: state.tickets_not_supervisor.map(ticket =>
          ticket.pk === action.payload.pk ? (ticket = action.payload) : ticket
        )
      };
    case UPDATE_TICKET_MINE:
      return {
        ...state,
        tickets_mine: state.tickets_mine.map(ticket =>
          ticket.pk === action.payload.pk ? (ticket = action.payload) : ticket
        )
      };
    case SEARCH_TICKET_TECHNICAL_NOT_SUPERVISOR:
      return {
        ...state,
        tickets_technical_not_supervisor: action.payload
      };
    case SEARCH_TICKET_TECHNICAL_MINE:
      return {
        ...state,
        tickets_technical_mine: action.payload
      };
    case DELETE_TICKET_TECHNICAL_NOT_SUPERVISOR:
      return {
        ...state,
        tickets_technical_not_supervisor: state.tickets_technical_not_supervisor.filter(ticket => ticket.pk !== action.payload)
      };
    case DELETE_TICKET_TECHNICAL_MINE:
      return {
        ...state,
        tickets_technical_mine: state.tickets_technical_mine.filter(ticket => ticket.pk !== action.payload)
      };
    case ADD_TICKET_TECHNICAL_NOT_SUPERVISOR:
      return {
        ...state,
        tickets_technical_not_supervisor: [...state.tickets_technical_not_supervisor, action.payload]
      };
    case ADD_TICKET_TECHNICAL_MINE:
      return {
        ...state,
        tickets_technical_mine: [...state.tickets_technical_mine, action.payload]
      };
    case TICKET_CREATED_BY_CLIENT:
      return {
        ...state,
        has_ticket_created: action.payload
      };
    case DELETE_TICKET_ENDED:
      return {
        ...state,
        tickets_ended: state.tickets_ended.filter(ticket_ended => ticket_ended.pk !== action.payload)
      };
    default:
      return state;
  }
}
