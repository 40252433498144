import {
  CHANGE_STATUS_DEBTORS_EXPORT_XLSX_TASK,
  CHANGE_STATUS_INFORMATION_EXPORT_XLSX_TASK,
  CHANGE_STATUS_INFORMATION_TICKETS_EXPORT_XLSX_TASK,
  DEBTORS_EXPORT_XLSX,
  DEBTORS_EXPORT_XLSX_TASK,
  INFORMATION,
  INFORMATION_EXPORT_XLSX,
  INFORMATION_EXPORT_XLSX_TASK,
  INFORMATION_TICKETS,
  INFORMATION_TICKETS_EXPORT_XLSX,
  INFORMATION_TICKETS_EXPORT_XLSX_TASK,
  LIST_XLSX_EXPORTED
} from "../../report/remote-service/types-collection";
import {INFORMATION_TASK} from "../remote-service/types-collection";

const initialState = {
  information: null,
  information_xlsx: null,
  information_xlsx_task: null,
  debtors_xlsx: null,
  debtors_xlsx_task: null,
  list_xlsx_exported: [],
  status_debtors_xlsx_task: null,
  status_information_xlsx_task: null,
  information_tickets: null,
  information_tickets_xlsx: null,
  information_tickets_xlsx_task: null,
  status_information_tickets_xlsx_task: null,
  information_task: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INFORMATION:
      return {
        ...state,
        information: action.payload
      };
    case INFORMATION_EXPORT_XLSX:
      return {
        ...state,
        information_xlsx: action.payload
      };
    case INFORMATION_EXPORT_XLSX_TASK:
      return {
        ...state,
        information_xlsx_task: action.payload
      };
    case DEBTORS_EXPORT_XLSX:
      return {
        ...state,
        debtors_xlsx: action.payload
      };
    case DEBTORS_EXPORT_XLSX_TASK:
      return {
        ...state,
        debtors_xlsx_task: action.payload
      };
    case LIST_XLSX_EXPORTED:
      return {
        ...state,
        list_xlsx_exported: action.payload
      };
    case CHANGE_STATUS_DEBTORS_EXPORT_XLSX_TASK:
      return {
        ...state,
        status_debtors_xlsx_task: action.payload
      };
    case CHANGE_STATUS_INFORMATION_EXPORT_XLSX_TASK:
      return {
        ...state,
        status_information_xlsx_task: action.payload
      };
    case INFORMATION_TICKETS:
      return {
        ...state,
        information_tickets: action.payload
      };
    case INFORMATION_TICKETS_EXPORT_XLSX:
      return {
        ...state,
        information_tickets_xlsx: action.payload
      };
    case INFORMATION_TICKETS_EXPORT_XLSX_TASK:
      return {
        ...state,
        information_tickets_xlsx_task: action.payload
      };
    case CHANGE_STATUS_INFORMATION_TICKETS_EXPORT_XLSX_TASK:
      return {
        ...state,
        status_information_tickets_xlsx_task: action.payload
      };
    case INFORMATION_TASK:
      return {
        ...state,
        information_task: action.payload
      };
    default:
      return state;
  }
}
