import {
  ADD_PRICE_LIST,
  DELETE_PRICE_LIST,
  DETAILS_PRICE_LIST,
  LIST_MIN_PRICE_LIST,
  SEARCH_PRICE_LIST,
  SEARCH_PRICE_LIST_NEXT,
  SEARCH_PRICE_LIST_PREVIOUS,
  UPDATE_PRICE_LIST
} from "../remote-service/types-collection";

const initialState = {
  priceLists: [],
  priceList: null,
  nextPage: null,
  previousPage: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_PRICE_LIST:
      return {
        ...state,
        priceLists: action.payload
      };
    case SEARCH_PRICE_LIST_NEXT:
      return {
        ...state,
        nextPage: action.payload
      };
    case SEARCH_PRICE_LIST_PREVIOUS:
      return {
        ...state,
        previousPage: action.payload
      };
    case DETAILS_PRICE_LIST:
      return {
        ...state,
        priceList: action.payload
      };
    case DELETE_PRICE_LIST:
      return {
        ...state,
        priceLists: state.priceLists.filter(price => price.pk !== action.payload)
      };
    case ADD_PRICE_LIST:
      return {
        ...state,
        priceLists: [...state.priceLists, action.payload]
      };
    case UPDATE_PRICE_LIST:
      return {
        ...state,
        priceLists: state.priceLists.map(price =>
          price.pk === action.payload.pk ? (price = action.payload) : price
        )
      };
    case LIST_MIN_PRICE_LIST:
      return {
        ...state,
        priceLists: action.payload
      };
    default:
      return state;
  }
}
