import {ADD_VOUCHER} from "../remote-service/types-collection";

const initialState = {
  vouchers: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_VOUCHER:
      return {
        ...state,
        vouchers: action.payload
      };
    default:
      return state;
  }
}
