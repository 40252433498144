import {DELETE_REFERENCE_PERSON} from '../remote-service/types-client'

const initialState = {
  referencePersons: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELETE_REFERENCE_PERSON:
      return {
        ...state,
        referencePersons: action.payload
      };
    default:
      return state;
  }
}
