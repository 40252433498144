import {
  ADD_NOTE_FROM_MODAL,
  DELETE_DATA,
  SEARCH_BY,
  SEARCH_BY_NAS_IP,
  SEARCH_BY_TYPE_CLIENT,
  SEARCH_DATA,
  SEARCH_DATA_NEXT,
  SEARCH_DATA_PREVIOUS,
  SEARCH_DATA_TOTAL_ROWS,
  UPDATE_LOADING
} from "../const/types";

const initialState = {
  data: [],
  next: null,
  previous: null,
  totalRows: 0,
  searchBy: "",
  searchByTypeClient: "",
  nasIp: "",
  loading: false,
  note: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_DATA:
      return {
        ...state,
        data: action.payload
      };
    case SEARCH_DATA_NEXT:
      return {
        ...state,
        next: action.payload
      };
    case SEARCH_DATA_PREVIOUS:
      return {
        ...state,
        previous: action.payload
      };
    case SEARCH_DATA_TOTAL_ROWS:
      return {
        ...state,
        totalRows: action.payload
      };
    case DELETE_DATA:
      return {
        ...state,
        data: state.data.filter(info => info.username !== action.payload)
      };
    case SEARCH_BY:
      return {
        ...state,
        searchBy: action.payload
      };
    case SEARCH_BY_TYPE_CLIENT:
      return {
        ...state,
        searchByTypeClient: action.payload
      };
    case UPDATE_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SEARCH_BY_NAS_IP:
      return {
        ...state,
        nasIp: action.payload
      };
    case ADD_NOTE_FROM_MODAL:
      return {
        ...state,
        note: action.payload
      };
    default:
      return state;
  }
}
