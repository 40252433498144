/**
 * Types Users
 * @type {string}
 */
export const SEARCH_USER = 'SEARCH_USER';
export const DETAILS_USER = 'DETAILS_USER';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const LIST_MIN_USER = 'LIST_MIN_USER';

/**
 * Types Roles
 * @type {string}
 */
export const SEARCH_ROLE = 'SEARCH_ROLE';
export const DETAILS_ROLE = 'DETAILS_ROLE';
export const ADD_ROLE = 'ADD_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const LIST_MIN_ROLE = 'LIST_MIN_ROLE';

/**
 * Types Permissions
 * @type {string}
 */
export const SEARCH_PERMISSION = 'SEARCH_PERMISSION';
export const LIST_MIN_PERMISSION = 'LIST_MIN_PERMISSION';

/**
 * Types Credentials
 * @type {string}
 */
export const LOGIN = 'LOGIN';
export const ISAUTH = 'ISAUTH';

