import {DETAILS_DEPOSIT, UPDATE_DEPOSIT} from "../remote-service/types-collection";

const initialState = {
  deposit: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DETAILS_DEPOSIT:
      return {
        ...state,
        deposit: action.payload
      };
    case UPDATE_DEPOSIT:
      return {
        ...state,
        deposit: action.payload
      };
    default:
      return state;
  }
}
