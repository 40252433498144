import {
  ADD_USER,
  DELETE_USER,
  DETAILS_USER,
  LIST_MIN_USER,
  SEARCH_USER,
  UPDATE_USER
} from "../remote-service/types-security";

const initialState = {
  users: [],
  users_min: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_USER:
      return {
        ...state,
        users: action.payload
      };
    case DETAILS_USER:
      return {
        ...state,
        user: action.payload
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter(user => user.pk !== action.payload)
      };
    case ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload]
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map(user =>
          user.pk === action.payload ? (user = action.payload) : user
        )
      };
    case LIST_MIN_USER:
      return {
        ...state,
        users_min: action.payload
      };
    default:
      return state;
  }
}
