import {ADD_KIT, DELETE_KIT, DETAILS_KIT, LIST_MIN_KIT, SEARCH_KIT, UPDATE_KIT} from "../remote-service/types-shopping";

const initialState = {
  kitMin: [],
  kits: [],
  kit: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_MIN_KIT:
      return {
        ...state,
        kitMin: action.payload
      };
    case SEARCH_KIT:
      return {
        ...state,
        kits: action.payload
      };
    case DETAILS_KIT:
      return {
        ...state,
        kit: action.payload
      };
    case DELETE_KIT:
      return {
        ...state,
        kits: state.kits.filter(kit => kit.pk !== action.payload)
      };
    case ADD_KIT:
      return {
        ...state,
        kits: [...state.kits, action.payload]
      };
    case UPDATE_KIT:
      return {
        ...state,
        kits: state.kits.map(kit =>
          kit.pk === action.payload ? (kit = action.payload) : kit
        )
      };
    default:
      return state;
  }
}
